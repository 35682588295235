<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <b-link to="/">
      <div class="brand-text">
        {{ appName }}
      </div>
    </b-link>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ nvaUsername }}</p>
            <span class="user-status">{{ status }}</span>
          </div>
          <!-- <b-avatar size="40" variant="light-primary" badge :src="require('@/assets/images/avatars/13-small.png')" class="badge-minimal" badge-variant="success" /> -->
          <b-avatar :src="imageProfile" size="40" variant="light-primary" class="badge-minimal" badge-variant="primary" badge-offset="-5px">
            <template #badge>
              <feather-icon icon="ChevronDownIcon" />
            </template>
          </b-avatar>
        </template>
        <!-- :active="$store.getters.getPage == 'user_edit' ? true : false" -->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="
            () => {
              $router.push({
                name: 'user_edit',
              });
            }
          "
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>โปรไฟล์</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MailIcon" class="mr-50" />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
          <span>Chat</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-divider /> -->

        <!-- <b-dropdown-item link-class="d-flex align-items-center" @click="logOut()">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>ออกจากระบบ</span>
        </b-dropdown-item> -->
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
  import { $themeConfig } from '@themeConfig';
  import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue';
  import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
  import axios from 'axios';
  import API from '@/connect/config';
  export default {
    components: {
      BLink,
      BNavbarNav,
      BNavItemDropdown,
      BDropdownItem,
      BDropdownDivider,
      BAvatar,

      // Navbar Components
      DarkToggler,
    },
    data() {
      return {
        nvaUsername: '',
        status: '',
        imageProfile: '',
        appName: '',
      };
    },

    async created() {
      // App Name
      //let { appName, appLogoImage } = $themeConfig.app;

      const { name } = JSON.parse(localStorage.getItem('company_vis'));
      this.appName = name;

      const { lineName, rule, imageProfile } = await JSON.parse(localStorage.getItem('profile_visitor'));

      this.nvaUsername = lineName;
      this.status = rule;
      if (imageProfile.length != 0) {
        this.imageProfile = await this.$store.dispatch('getImageURL', imageProfile[0]).then((res) => res);
      }
    },
    methods: {
      async logOut() {
        const refresh_token = JSON.parse(localStorage.getItem('refresh_token'));
        this.$store
          .dispatch('postData', {
            _url: 'logout',
            _data: { refresh_token: refresh_token },
          })
          .then((res) => {
            console.log(res);
            this.$router.push({ name: 'login' });
            this.$store.commit('setToastMessage', {
              title: 'Logout',
              message: 'Logout Success',
              variant: 'warning',
            });
          })
          .catch((error) => {
            console.log(error.response);
            //this.$router.push({ name: 'login' });
          });
        // console.log(res);
      },
    },
    props: {
      toggleVerticalMenuActive: {
        type: Function,
        default: () => {},
      },
    },
  };
</script>
