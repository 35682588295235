



export default [
  {
    title: 'หน้าหลัก',
    route: 'home',
    icon: 'HomeIcon',
  },

  //announce
  {
    title: 'ประกาศชุมชน',
    route: 'announceinfor',
    icon: 'BellIcon',
  },
  {
    title: 'ข้อมูลธุรกิจ',
    route: 'businessinfor',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'ข้อมูลบริการ',
    route: 'serviceinfor',
    icon: 'BookOpenIcon',
  },
  {
    title: 'ข้อมูลท่องเที่ยว',
    route: 'travelinfor',
    icon: 'MapIcon',
  },

  //ticket
  {
    title: 'แจ้งเหตุ',
    route: 'inform',
    icon: 'AlertTriangleIcon',
  },
  {
    title: 'แจ้งซ่อมบำรุง',
    route: 'maintenance',
    icon: 'ToolIcon',
  },
  {
    title: 'ร้องเรียน',
    route: 'appeal',
    icon: 'ArchiveIcon',
  },

  //map
  // {
  //   title: 'เเจ้งเหตุ',
  //   route: 'reportofincident',
  //   icon: 'AlertTriangleIcon',
  // },
  // {
  //   title: 'ภาพเเผ่นที่',
  //   route: 'reportofincident',
  //   icon: 'MapIcon',
  // },

  {
    title: 'นัดหมาย',
    icon: 'CalendarIcon',
    children: [
      // {
      //   title: 'สร้างนัดหมาย',
      //   route: 'addMeet',
      // },
      {
        title: 'ประวัติการเข้าพบ',
        route: 'historyMeet',
      },
    ],
  },
  // {
  //   title: 'ลงทะเบียนผู้ใช้งาน',
  //   route: 'user_regis',
  //   icon: 'UserPlusIcon',
  // },
];
